import './App.css';
import './Custom.css';
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Gale from "./pages/Gale";
import Home from "./pages/Home";
import Jollie from "./pages/Jollie";
import Login from "./pages/Login";
import PropChat from "./pages/PropChat";
import MasterCloser from "./pages/MasterCloser";
import MasterTrainer from "./pages/MasterTrainer";

function App() {
	const RedirectToExternalUrl = ({ url }) => {
		window.location.href = url;
		return null;
	};
	
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="login" element={<Login />} />
                    <Route path="propchat" element={<PropChat />} />
                    <Route path="mastercloser" element={<MasterCloser />} />
                    <Route path="mastertrainer" element={<MasterTrainer />} />
                </Route>
                <Route path="gale/:topic" element={<Gale />} />
                <Route path="jollie" element={<Jollie />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
