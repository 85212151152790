import React from 'react';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Config from '../Config.json';

function Login() {
    const navigate = useNavigate();
    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');
    const [submittedLogin, setSubmittedLogin] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    
    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmittedLogin(true);
        authenticate();
    };

    function authenticate() {
        fetch('https://hb9zixnwnb.execute-api.us-east-2.amazonaws.com/aiauthenticate', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': Config.ai_authenticate,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'user_id': userId,
                'password': password
            })
        })
            .then((response) => response.json())
            .then((response) => {
                if (response['token'].length === 32) {
                    localStorage.setItem('user_id', response.user_id);
                    localStorage.setItem('optimus', response.token);
                    localStorage.setItem('first_name', response.first_name);
                    localStorage.setItem('last_name', response.last_name);
                    navigate('/');
                    window.location.reload();
                } else {
                    localStorage.setItem('user_id', '');
                    localStorage.setItem('optimus', '');
                    localStorage.setItem('first_name', '');
                    localStorage.setItem('last_name', '');
                    setErrorMessage('Invalid login');
                    setSubmittedLogin(false);
                }
            })
            .catch(function (err) {
                console.log('Error:', err);
            });
    }

    return (
        <>
            <div id="layoutAuthentication" style={{ backgroundImage: "linear-gradient(to top, #48c6ef 0%, #6f86d6 100%)" }}>
                <div>
                    <main>
                        <div>
                            <div>
                                <form onSubmit={handleSubmit} style={{ width: '300px' }}>
                                    <br />
                                    <div className="form-group">
                                        <label htmlFor="userId">Email:</label>
                                        <input id="userId" name="userId" type="text" value={userId} onChange={(event) => setUserId(event.target.value)} autoFocus />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="passwordExample">Password:</label>
                                        <input id="passwordExample" type="password" value={password} onChange={(event) => setPassword(event.target.value)} />
                                    </div>
                                    <div className="container">
                                        <div className="row">
                                        <div className="col text-center">
                                            <button type="submit" className="btn btn-primary btn-block mb-4" disabled={submittedLogin}>Login</button>
                                            <div className="text-danger">{errorMessage}</div>
                                        </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </main>
                </div>
                <div id="layoutAuthentication_footer">
                    <footer className="footer-admin mt-auto footer-dark">
                        <div className="container-xl px-4">
                            <div className="row">
                                {/* <div className="col-md-6 small">Copyright &copy;  US AI Academy 2023</div> */}
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </>
    );
    
}

export default Login;
