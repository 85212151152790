import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Button, ProgressBar, Form, Row, Col } from 'react-bootstrap';
import Config from '../Config.json';
import Zig from './Zig';

const MasterCloser = () => {
    const navigate = useNavigate();
    const [client, setClient] = useState({
        'company_id': '',
        'client_id': '',
        'first_name': '',
        'last_name': '',
        'family_children': '',
        'favorite_sports_team': '',
        'goals_aspirations': '',
        'hobby_interest': '',
        'occupation': '',
        'pain_points': '',
        'interactions': [],
        'sales_qa': [],
        'sales_suggestions': []
    });
    const [expandedIndex, setExpandedIndex] = useState(null);
    const [expandAll, setExpandAll] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [url, setUrl] = useState('https://www.linkedin.com/in/jazonsamillano/');

    const [initialAdvice, setInitialAdvice] = useState('');
    const [scenario, setScenario] = useState('');

    useEffect(() => {
        checkLogin();
        getClient();
        
    }, [])
    
    const checkLogin = () => {
        if (localStorage.getItem('optimus') === null || String(localStorage.getItem('optimus')).length !== parseInt(process.env.REACT_APP_TOKEN_LENGTH)) {
            navigate('/login');
        }
    }

    const fetchProfile = async () => {
        setLoading(true);

        // Replace with your actual API fetch call
        try {
            const response = await fetch(url);
            const data = await response.json();
            console.log(data);
            // Process data here...
        } catch (error) {
            console.log(error);
            // Handle error...
        }

        setLoading(false);
    };

    const getClient = () => {
        fetch('https://qmjgk93qmi.execute-api.us-east-2.amazonaws.com/aiclientdetails', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': Config.ai_client_details,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'company_id': '9ff940b0',
                'client_id': '4f7f7f35'
            })
        })
            .then((response) => response.json())
            .then((response) => {
                setClient(response);
                getScenario(response);
                console.log(response);
            })
            .catch(function (err) {
                console.log('Error:', err);
            });
    }

    const getScenario = (clientData) => {
        setScenario("We want to maximize the probability of doing business with this client: " + JSON.stringify(clientData));
        setInitialAdvice("Here's a piece of advice from Zig Ziglar:\n\n\"Jazon Samillano's pain point of not being able to keep up with the pace of AI progress is a common challenge that many clients face. As a salesperson, it's essential to understand Jazon's needs and goals to offer a customized solution that addresses his concerns. One approach could be to position Perficient's expertise in AI and emerging technologies as a solution to help Jazon stay up-to-date with the latest developments.\n\nIt's also crucial to build a strong rapport with Jazon by actively listening to his concerns and providing personalized recommendations and solutions. By demonstrating your commitment to helping him achieve his goals and overcome his challenges, you can establish trust and differentiate yourself from other technology consulting firms.\n\nIn addition, incorporating Jazon's hobby interest in chess and family situation with a young son named Magnus into your proposal can make him feel valued and increase the chances of closing the deal. Remember, selling is not about manipulating or persuading the client, but about helping them achieve their goals and solving their problems.\"");
    }

    const toggleItem = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    const toggleExpandAll = () => {
        setExpandAll(!expandAll);
        setExpandedIndex(null);
    };

    const renderTextWithLineBreaks = (text) => {
        return text.split('\n\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                {index < text.split('\n\n').length - 1 && <div className="lineBreak"></div>}
            </React.Fragment>
        ));
    };

    return (
        <>
            {/* Top Cards */}
            <div className="row">
                <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                    <div className="card">
                        <div className="card-body p-3">
                            <div className="row">
                                <div className="col-8">
                                    <div className="numbers">
                                        <p className="text-sm mb-0 text-uppercase font-weight-bold">Daily Points</p>
                                        <h5 className="font-weight-bolder">
                                            2 points
                                        </h5>
                                        <p className="mb-0">
                                            <span className="text-success text-sm font-weight-bolder">+100%</span>&nbsp;
                                            since yesterday
                                        </p>
                                    </div>
                                </div>
                                <div className="col-4 text-end">
                                    <div className="icon icon-shape bg-gradient-primary shadow-primary text-center rounded-circle">
                                        <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                    <div className="card">
                        <div className="card-body p-3">
                            <div className="row">
                                <div className="col-8">
                                    <div className="numbers">
                                        <p className="text-sm mb-0 text-uppercase font-weight-bold">Weekly Points</p>
                                        <h5 className="font-weight-bolder">
                                            5
                                        </h5>
                                        <p className="mb-0">
                                            <span className="text-success text-sm font-weight-bolder">+25%</span>&nbsp;
                                            since last week
                                        </p>
                                    </div>
                                </div>
                                <div className="col-4 text-end">
                                    <div className="icon icon-shape bg-gradient-danger shadow-danger text-center rounded-circle">
                                        <i className="ni ni-world text-lg opacity-10" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                    <div className="card">
                        <div className="card-body p-3">
                            <div className="row">
                                <div className="col-8">
                                    <div className="numbers">
                                        <p className="text-sm mb-0 text-uppercase font-weight-bold">Monthly Points</p>
                                        <h5 className="font-weight-bolder">
                                            19
                                        </h5>
                                        <p className="mb-0">
                                            <span className="text-danger text-sm font-weight-bolder">-21%</span>&nbsp;
                                            since last month
                                        </p>
                                    </div>
                                </div>
                                <div className="col-4 text-end">
                                    <div className="icon icon-shape bg-gradient-success shadow-success text-center rounded-circle">
                                        <i className="ni ni-paper-diploma text-lg opacity-10" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                    <div className="card">
                        <div className="card-body p-3">
                            <div className="row">
                                <div className="col-8">
                                    <div className="numbers">
                                        <p className="text-sm mb-0 text-uppercase font-weight-bold">Total Points</p>
                                        <h5 className="font-weight-bolder">
                                            64
                                        </h5>
                                        <p className="mb-0">
                                            <span className="text-success text-sm font-weight-bolder">Superstar performer</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-4 text-end">
                                    <div className="icon icon-shape bg-gradient-warning shadow-warning text-center rounded-circle">
                                        <i className="ni ni-cart text-lg opacity-10" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="row mt-4">
                <div className="col-lg-6 mb-lg-0 mb-4">
                    <div className="card ">
                        {/* Client Data */}
                        <div className="card-header pb-0 p-3">
                            <div className="d-flex justify-content-between">
                                <h5 className="mb-2"><b>{client.first_name} {client.last_name}</b></h5>
                            </div>
                            <div style={{height: "14px"}}></div>
                            <div>
                                <div className="container">
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <th className="wrap-text">Occupation</th>
                                                    <td>{client.occupation}</td>
                                                </tr>
                                                <tr>
                                                    <th className="wrap-text">Family</th>
                                                    <td>{client.family_children}</td>
                                                </tr>
                                                <tr>
                                                    <th className="wrap-text">Goals and Aspirations</th>
                                                    <td>{client.goals_aspirations}</td>
                                                </tr>
                                                <tr>
                                                    <th className="wrap-text">Pain Points</th>
                                                    <td>{client.pain_points}</td>
                                                </tr>
                                                <tr>
                                                    <th className="wrap-text">Hobbies and Interests</th>
                                                    <td>{client.hobby_interest}</td>
                                                </tr>
                                                <tr>
                                                    <th className="wrap-text">Favorite Sports Team</th>
                                                    <td>{client.favorite_sports_team}</td>
                                                </tr>
                                                <tr>
                                                    <th className="wrap-text">Interactions</th>
                                                    <td>
                                                        <ol>
                                                            {client.interactions.map((item, index) => (
                                                                <li key={index}>{item}</li>
                                                            ))}
                                                        </ol>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container">
                            {/* <Form.Group>
                                <Form.Label>Enter URL</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={url}
                                    onChange={e => setUrl(e.target.value)}
                                    placeholder="Enter URL"
                                    style={{ width: '300px' }}
                                />
                            </Form.Group>
                            <Button onClick={fetchProfile}>Submit</Button> */}

                            <Row>
                                <Col md="auto">
                                    <Form.Control
                                        type="text"
                                        value={url}
                                        onChange={e => setUrl(e.target.value)}
                                        placeholder="Enter URL"
                                        style={{ width: '400px' }}
                                    />
                                </Col>
                                <Col md="auto">
                                    {/* <Button onClick={fetchProfile}>Submit</Button> */}
                                    <button className="simple-btn" onClick={fetchProfile}>Get Profile</button>
                                </Col>
                            </Row>

                            {isLoading && <ProgressBar animated now={100} />}
                        </div>
                        
                        {/* Master Sales Advice */}
                        <div className="card-header pb-0 p-3">
                            <div className="d-flex justify-content-between">
                                <h5 className="mb-2"><b>Master Sales Advice</b></h5>
                            </div>
                            <div style={{height: "8px"}}></div>
                            <div>
                                {client.sales_suggestions[0] ? renderTextWithLineBreaks(String(client.sales_suggestions[0])) : ''}<br /><br />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Master Closing Plan */}
                <div className="col-lg-6 mb-lg-0 mb-4">
                    <div className="card ">
                        <div className="card-header pb-0 p-3">
                            
                            {/* <div className="d-flex justify-content-between">
                                <h6 className="mb-2"><b>Closing Plan</b></h6>
                            </div> */}

                            <div className="header-container">
                                <h5><b>Master Closing Plan</b></h5>
                                <button className="simple-btn" onClick={toggleExpandAll}>
                                    {expandAll ? 'Collapse All' : 'Expand All'}
                                </button>
                            </div>

                            <div style={{height: "14px"}}></div>
                            <div>
                                <div className="container">
                                    {/* <button className="expand-all-btn" onClick={toggleExpandAll}>
                                        {expandAll ? 'Collapse All' : 'Expand All'}
                                    </button> */}
                                    <div>
                                        {client.sales_qa.map((item, index) => (
                                        <div className="qa-item" key={index}>
                                            <button className="qa-question" onClick={() => toggleItem(index)}>
                                                {item.q}
                                            </button>
                                            {(expandedIndex === index || expandAll) && (
                                            <div className="qa-answer">
                                                {item.a}
                                            </div>
                                            )}
                                        </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Sales Trainer */}
                        <div className="card-header pb-0 p-3">
                            <div className="d-flex justify-content-between">
                                
                            </div>
                            <div style={{height: "8px"}}></div>
                            <div>
                                {/* <Zig scenario={scenario} /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MasterCloser;
