import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Zig from './Zig';

const MasterTrainer = () => {
    const navigate = useNavigate();
    const [initialAdvice, setInitialAdvice] = useState('');
    const [scenario, setScenario] = useState('');

    useEffect(() => {
        checkLogin();
        getScenario();
    }, [])
    
    const checkLogin = () => {
        if (localStorage.getItem('optimus') === null || String(localStorage.getItem('optimus')).length !== parseInt(process.env.REACT_APP_TOKEN_LENGTH)) {
            navigate('/login');
        }
    }

    const getScenario = () => {
        setScenario("The client has raised the following objection: \"I'm not sure if I'm ready for the commitment and discipline the Army requires.\"");
        setInitialAdvice("Zig Ziglar, a renowned sales expert, suggests handling objections with empathy and understanding. In this situation, validate the prospect's concerns by acknowledging that the Army requires commitment and discipline. Then, shift the focus to the benefits the Army can offer, such as leadership development, job training, and educational opportunities. Share stories of how others overcame similar concerns and succeeded in the Army. Finally, ask the prospect to consider the long-term advantages and personal growth that a career in the Army can provide. This approach addresses the objection while highlighting the positives.");
    }

    return (
        <>
            {/* Top Cards */}
            <div className="row">
                <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                    <div className="card">
                        <div className="card-body p-3">
                            <div className="row">
                                <div className="col-8">
                                    <div className="numbers">
                                        <p className="text-sm mb-0 text-uppercase font-weight-bold">Daily Points</p>
                                        <h5 className="font-weight-bolder">
                                            2 points
                                        </h5>
                                        <p className="mb-0">
                                            <span className="text-success text-sm font-weight-bolder">+100%</span>&nbsp;
                                            since yesterday
                                        </p>
                                    </div>
                                </div>
                                <div className="col-4 text-end">
                                    <div className="icon icon-shape bg-gradient-primary shadow-primary text-center rounded-circle">
                                        <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                    <div className="card">
                        <div className="card-body p-3">
                            <div className="row">
                                <div className="col-8">
                                    <div className="numbers">
                                        <p className="text-sm mb-0 text-uppercase font-weight-bold">Weekly Points</p>
                                        <h5 className="font-weight-bolder">
                                            5
                                        </h5>
                                        <p className="mb-0">
                                            <span className="text-success text-sm font-weight-bolder">+25%</span>&nbsp;
                                            since last week
                                        </p>
                                    </div>
                                </div>
                                <div className="col-4 text-end">
                                    <div className="icon icon-shape bg-gradient-danger shadow-danger text-center rounded-circle">
                                        <i className="ni ni-world text-lg opacity-10" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                    <div className="card">
                        <div className="card-body p-3">
                            <div className="row">
                                <div className="col-8">
                                    <div className="numbers">
                                        <p className="text-sm mb-0 text-uppercase font-weight-bold">Monthly Points</p>
                                        <h5 className="font-weight-bolder">
                                            19
                                        </h5>
                                        <p className="mb-0">
                                            <span className="text-danger text-sm font-weight-bolder">-21%</span>&nbsp;
                                            since last month
                                        </p>
                                    </div>
                                </div>
                                <div className="col-4 text-end">
                                    <div className="icon icon-shape bg-gradient-success shadow-success text-center rounded-circle">
                                        <i className="ni ni-paper-diploma text-lg opacity-10" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                    <div className="card">
                        <div className="card-body p-3">
                            <div className="row">
                                <div className="col-8">
                                    <div className="numbers">
                                        <p className="text-sm mb-0 text-uppercase font-weight-bold">Total Points</p>
                                        <h5 className="font-weight-bolder">
                                            64
                                        </h5>
                                        <p className="mb-0">
                                            <span className="text-success text-sm font-weight-bolder">Superstar performer</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-4 text-end">
                                    <div className="icon icon-shape bg-gradient-warning shadow-warning text-center rounded-circle">
                                        <i className="ni ni-cart text-lg opacity-10" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="row mt-4">
                {/* Scenario and Advice */}
                <div className="col-lg-6 mb-lg-0 mb-4">
                    <div className="card ">
                        <div className="card-header pb-0 p-3">
                            <div className="d-flex justify-content-between">
                                <h6 className="mb-2"><b>Scenario</b></h6>
                            </div>
                            <div>{scenario}</div>
                        </div>
                        <div className="card-header pb-0 p-3">
                            <div className="d-flex justify-content-between">
                                <h6 className="mb-2"><b>Advice from a Professional Sales Trainer</b></h6>
                            </div>
                            <div>
                                {initialAdvice}<br /><br />
                                <button className="btn btn-dark" type="button" data-bs-toggle="modal" data-bs-target="#quiz_modal">Answer one question about this sales advice to earn 1 point</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Sales Performance Simulator */}
                <Zig scenario={scenario} />

                {/* Quiz Modal */}
                <div className="modal fade" id="quiz_modal" tabIndex={-1} role="dialog" aria-labelledby="quiz_modal" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-xl" role="document" style={{maxWidth: "500px"}}>
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="row">
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-md-12 col-lg-10 col-xl-12">
                                            <div className="card overflow-auto" style={{ maxHeight: "500px", display: "flex", flexDirection: "column-reverse" }}>
                                                <div className="card-body">
                                                    Quiz
                                                </div>
                                            </div>
                                            <div className="card-footer py-3 border-0" style={{ backgroundColor: "#f8f9fa" }}>
                                                <div className="d-flex flex-start w-100">
                                                    <div className="form-outline w-100">
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div className="float-end mt-4 pt-1">
                                                    <button type="button" className="btn btn-primary" onClick={() => console.log('Quiz submitted')} style={{width: "100px"}}>Submit</button> &nbsp;&nbsp;
                                                    <button type="button" className="btn " data-bs-dismiss="modal" style={{ width: '100px' , backgroundColor: "#808080" , color: 'white'}}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MasterTrainer;
