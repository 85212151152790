import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useWhisper } from '@chengsokdara/use-whisper'
import AudioLinkPlayer from './AudioLinkPlayer';
import Config from '../Config.json';

const Zig = ({ scenario }) => {
    const {
        recording,
        speaking,
        transcribing,
        transcript,
        pauseRecording,
        startRecording,
        stopRecording,
    } = useWhisper({
        apiKey: process.env.REACT_APP_API_TOKEN,
    })
    const navigate = useNavigate();
    const [action, setAction] = useState('');
    const [activeButton, setActiveButton] = useState('stop');
    const [currentScriptSegue, setCurrentScriptSegue] = useState('');
    const [kant, setKant] = useState({'advice': '', 'script': ''});
    const [audioFileUrl, setAudioFileUrl] = useState('');

    useEffect(() => {
        setCurrentScriptSegue(scriptSegue());
    }, [])
    
    useEffect(() => {
        // Update the state variable whenever
        // the transcript changes.
        if (transcript.text) {
            askKant(scenario, transcript.text);
        }
    }, [transcript]);

    const askKant = (scenario, attempt) => {
        setAction('Responding');
        fetch('https://g7hkyyb77e.execute-api.us-east-2.amazonaws.com/aiaskkant', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': Config.ai_ask_kant,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'scenario': scenario,
                'attempt': attempt,
                'source': 'simulator'
            })
        })
            .then((response) => response.json())
            .then((response) => {
                setKant(response);
                setAction('Speaking');
                speak(response.advice + ' ' + currentScriptSegue + ' ... ' + response.script, 'Matthew');
                console.log(response);
            })
            .catch(function (err) {
                console.log('Error:', err);
            });
    }

    const scriptSegue = () => {
        return "Here's a really nice way to respond to that scenario: ";
    }

    const speak = (textToSpeak, speaker) => {
        fetch('https://gsbzosmjwb.execute-api.us-east-2.amazonaws.com/aitts', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': Config.ai_tts,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'text_to_speak': textToSpeak,
                'speaker': speaker
            })
        })
            .then((response) => response.json())
            .then((response) => {
                setAudioFileUrl(response.url);
                console.log(response);
            })
            .catch(function (err) {
                console.log('Error:', err);
            });
    }

    return (
        <div className="col-lg-6 mb-lg-0 mb-4">
            <div className="card ">
                <div className="card-header pb-0 p-3">
                    <div className="d-flex justify-content-between">
                        <h6 className="mb-2"><b>Sales Performance Simulator</b></h6>
                        <div style={{height: "42px"}}>&nbsp;</div>
                    </div>
                </div>
                <div>
                    <div style={{marginLeft: "15px"}}>
                        <button
                            className="btn btn-primary"
                            style={{width: "100px", marginRight: "18px"}}
                            onClick={() => {
                                startRecording();
                                setAction('Recording');
                                setActiveButton('start');
                                setAudioFileUrl('');
                            }}
                            disabled={activeButton === 'start'}
                        >Start</button>
                        <button
                            className="btn btn-primary"
                            style={{width: "100px"}}
                            onClick={() => {
                                stopRecording();
                                setAction('Transcribing');
                                setActiveButton('stop');
                            }}
                            disabled={activeButton === 'stop'}
                        >Stop</button><br />
                        <div>
                            {audioFileUrl ? <AudioLinkPlayer audioLink={audioFileUrl} />
                                : ['Transcribing', 'Responding'].includes(action) ? <AudioLinkPlayer audioLink={'https://storage.googleapis.com/activation-function/audio/stalling_02.mp3'} />
                                : ''}
                        </div>
                    </div>
                    <div style={{marginLeft: "15px"}}>
                        {action ? 
                            <p><b>You:</b> &nbsp; {['Recording', 'Transcribing'].includes(action) ? action + '...' : transcript.text}</p>
                            : ''
                        }
                        {/* {action === 'Responding' || action === 'Speaking' ?
                            <p><b>Customer:</b> &nbsp; {action === 'Responding' ? 'Responding...' : kant.challenge}</p>
                            : ''
                        } */}
                        {action === 'Speaking' ?
                            <p>
                                <b>Magnus:</b> &nbsp; {kant.advice}<br /><br />
                                {currentScriptSegue}"{kant.script}"
                            </p>
                            : ''
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Zig;
