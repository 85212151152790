import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import useWebSocket from 'react-use-websocket';

const WS_URL = 'wss://6aiolymuhf.execute-api.us-east-2.amazonaws.com/prod';

const Layout = () => {
    const navigate = useNavigate();
	const [loggedIn, setLoggedIn] = useState(false);
	const [userId] = useState(localStorage.getItem('user_id'));
    const [firstName] = useState(localStorage.getItem('first_name'));
    const [lastName] = useState(localStorage.getItem('last_name'));
    const [serverMessage, setServerMessage] = useState('');
	// const {getWebSocket} = useWebSocket(WS_URL + '?userId=' + userId, {
	// 	onOpen: () => console.log('Connected!'),
	// 	onMessage: (event) => {
	// 		setServerMessage(event.data);
	// 		// fetchData();
	// 		console.log(event.data);
	// 	},
	// 	shouldReconnect: (closeEvent) => true,
	// });

    useEffect(() => {
        var optimus = localStorage.getItem('optimus');
        
        if (optimus && optimus.length === 32) {
            setLoggedIn(true);
        } else {
            setLoggedIn(false);
        }
        
    }, [loggedIn])

	function changeDesign(changeRequest) {
		
	}

    return (
        <>
            <Header />
            <Body />
            <Footer />
        </>
    )

    function Header() {
        return (
            <>
            </>
        )
    }

    function Body() {
        return (
            <>
				<div className="min-height-300 bg-primary position-absolute w-100"></div>

				<aside className="sidenav bg-white navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-4 " id="sidenav-main">
					<div className="sidenav-header">
						<i className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i>
						<a className="navbar-brand m-0" href="/">
						<img src="./images/magnus-logo.png" className="navbar-brand-img h-100" style={{borderRadius: "3px"}} alt="main_logo" />&nbsp;
						<span className="ms-1 font-weight-bold">US AI Academy</span>
						</a>
					</div>
					<hr className="horizontal dark mt-0" />
					<div className="collapse navbar-collapse  w-auto " id="sidenav-collapse-main">
						<ul className="navbar-nav">
						<li className="nav-item">
							<Link to="/" className="nav-link">
								<div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
									<i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
								</div>
								<span className="nav-link-text ms-1">Home</span>
							</Link>
						</li>
						<li className="nav-item">
							<Link to="/propchat" className="nav-link">
								<div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
									<i className="ni ni-credit-card text-success text-sm opacity-10"></i>
								</div>
								<span className="nav-link-text ms-1">PropChat</span>
							</Link>
						</li>
						<li className="nav-item">
							<Link to="/mastercloser" className="nav-link">
								<div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
									<i className="ni ni-credit-card text-success text-sm opacity-10"></i>
								</div>
								<span className="nav-link-text ms-1">Master Closer</span>
							</Link>
						</li>
						<li className="nav-item">
							<Link to="/mastertrainer" className="nav-link">
								<div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
									<i className="ni ni-credit-card text-success text-sm opacity-10"></i>
								</div>
								<span className="nav-link-text ms-1">Master Trainer</span>
							</Link>
						</li>
						<li className="nav-item mt-3">
							<h6 className="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6">Account</h6>
						</li>
						<li className="nav-item">
							<a className="nav-link " href="./pages/profile.html">
							<div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
								<i className="ni ni-single-02 text-dark text-sm opacity-10"></i>
							</div>
							<span className="nav-link-text ms-1">Profile</span>
							</a>
						</li>
						<li className="nav-item">
							{/* <a className="nav-link " href="./pages/sign-up.html">
								<div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
									<i className="ni ni-collection text-info text-sm opacity-10"></i>
								</div>
								<span className="nav-link-text ms-1">Logout</span>
							</a> */}
							<Link to="#" onClick={Logout} className="nav-link">
								<div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
									<i className="ni ni-collection text-info text-sm opacity-10"></i>
								</div>
								<span className="nav-link-text ms-1">Logout</span>
							</Link>
						</li>
						</ul>
					</div>
					<div className="sidenav-footer mx-3 " style={{ display: "none", visibility: "hidden" }}>
						<div className="card card-plain shadow-none" id="sidenavCard">
						<img className="w-50 mx-auto" src="./assets/img/illustrations/icon-documentation.svg" alt="sidebar_illustration" />
						<div className="card-body text-center p-3 w-100 pt-0">
							<div className="docs-info">
							<h6 className="mb-0">Need help?</h6>
							<p className="text-xs font-weight-bold mb-0">Please check our docs</p>
							</div>
						</div>
						</div>
						<a href="https://www.creative-tim.com/learning-lab/bootstrap/license/argon-dashboard" target="_blank" className="btn btn-dark btn-sm w-100 mb-3">Documentation</a>
						<a className="btn btn-primary btn-sm mb-0 w-100" href="https://www.creative-tim.com/product/argon-dashboard-pro?ref=sidebarfree" type="button">Upgrade to pro</a>
					</div>
				</aside>
				<main className="main-content position-relative border-radius-lg">
					{/* Navbar */}
					<nav className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl " id="navbarBlur" data-scroll="false">
						<div className="container-fluid py-1 px-3">
							{/* <nav aria-label="breadcrumb">
								<ol className="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
								<li className="breadcrumb-item text-sm"><a className="opacity-5 text-white" href="#">Pages</a></li>
								<li className="breadcrumb-item text-sm text-white active" aria-current="page">Dashboard</li>
								</ol>
								<h6 className="font-weight-bolder text-white mb-0">Dashboard</h6>
							</nav> */}
							<div className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
								<div className="ms-md-auto pe-md-3 d-flex align-items-center">
								<div className="input-group">
									<span className="input-group-text text-body"><i className="fas fa-search" aria-hidden="true"></i></span>
									<input type="text" className="form-control" placeholder="Search" />
								</div>
								</div>
								<ul className="navbar-nav  justify-content-end">
									<li className="nav-item d-flex align-items-center">
										<a href="#" className="nav-link text-white font-weight-bold px-0">
										<i className="fa fa-user me-sm-1"></i>&nbsp;
										<span className="d-sm-inline d-none">Logout</span>
										</a>
									</li>
									<li className="nav-item d-xl-none ps-3 d-flex align-items-center">
										<a href="#" className="nav-link text-white p-0" id="iconNavbarSidenav">
										<div className="sidenav-toggler-inner">
											<i className="sidenav-toggler-line bg-white"></i>
											<i className="sidenav-toggler-line bg-white"></i>
											<i className="sidenav-toggler-line bg-white"></i>
										</div>
										</a>
									</li>
									<li className="nav-item px-3 d-flex align-items-center">
										<a href="#" className="nav-link text-white p-0">
										<i className="fa fa-cog fixed-plugin-button-nav cursor-pointer"></i>
										</a>
									</li>
									{/* <li className="nav-item dropdown pe-2 d-flex align-items-center">
										<a href="#" className="nav-link text-white p-0" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
										<i className="fa fa-bell cursor-pointer"></i>
										</a>
										<ul className="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4" aria-labelledby="dropdownMenuButton">
										<li className="mb-2">
											<a className="dropdown-item border-radius-md" href="#">
											<div className="d-flex py-1">
												<div className="my-auto">
												<img src="./assets/img/team-2.jpg" className="avatar avatar-sm  me-3 " />
												</div>
												<div className="d-flex flex-column justify-content-center">
												<h6 className="text-sm font-weight-normal mb-1">
													<span className="font-weight-bold">New message</span> from Laur
												</h6>
												<p className="text-xs text-secondary mb-0">
													<i className="fa fa-clock me-1"></i>
													13 minutes ago
												</p>
												</div>
											</div>
											</a>
										</li>
										<li className="mb-2">
											<a className="dropdown-item border-radius-md" href="#">
											<div className="d-flex py-1">
												<div className="my-auto">
												<img src="./assets/img/small-logos/logo-spotify.svg" className="avatar avatar-sm bg-gradient-dark  me-3 " />
												</div>
												<div className="d-flex flex-column justify-content-center">
												<h6 className="text-sm font-weight-normal mb-1">
													<span className="font-weight-bold">New album</span> by Travis Scott
												</h6>
												<p className="text-xs text-secondary mb-0">
													<i className="fa fa-clock me-1"></i>
													1 day
												</p>
												</div>
											</div>
											</a>
										</li>
										<li>
											<a className="dropdown-item border-radius-md" href="#">
											<div className="d-flex py-1">
												<div className="avatar avatar-sm bg-gradient-secondary  me-3  my-auto">
												<svg width="12px" height="12px" viewBox="0 0 43 36" version="1.1">
													<title>credit-card</title>
													<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
													<g transform="translate(-2169.000000, -745.000000)" fill="#FFFFFF" fillRule="nonzero">
														<g transform="translate(1716.000000, 291.000000)">
														<g transform="translate(453.000000, 454.000000)">
															<path className="color-background" d="M43,10.7482083 L43,3.58333333 C43,1.60354167 41.3964583,0 39.4166667,0 L3.58333333,0 C1.60354167,0 0,1.60354167 0,3.58333333 L0,10.7482083 L43,10.7482083 Z" opacity="0.593633743"></path>
															<path className="color-background" d="M0,16.125 L0,32.25 C0,34.2297917 1.60354167,35.8333333 3.58333333,35.8333333 L39.4166667,35.8333333 C41.3964583,35.8333333 43,34.2297917 43,32.25 L43,16.125 L0,16.125 Z M19.7083333,26.875 L7.16666667,26.875 L7.16666667,23.2916667 L19.7083333,23.2916667 L19.7083333,26.875 Z M35.8333333,26.875 L28.6666667,26.875 L28.6666667,23.2916667 L35.8333333,23.2916667 L35.8333333,26.875 Z"></path>
														</g>
														</g>
													</g>
													</g>
												</svg>
												</div>
												<div className="d-flex flex-column justify-content-center">
												<h6 className="text-sm font-weight-normal mb-1">
													Payment successfully completed
												</h6>
												<p className="text-xs text-secondary mb-0">
													<i className="fa fa-clock me-1"></i>
													2 days
												</p>
												</div>
											</div>
											</a>
										</li>
										</ul>
									</li> */}
								</ul>
							</div>
						</div>
					</nav>
					{/* End Navbar */}

					{/* Main Content */}
					<div className="container-fluid py-4">
						<Outlet />
					</div>

					{/* Footer */}
					<div>
						<footer className="footer pt-1">
							<div className="container-fluid">
								<div className="row align-items-center justify-content-lg-between">
								<div className="col-lg-6 mb-lg-0 mb-4">
									<br />
									<div className="copyright text-center text-sm text-muted text-lg-start">
										<small>Copyright © 2024 US AI Academy. All rights reserved.</small>
									</div>
								</div>
								<div className="col-lg-6" style={{ display: "none", visibility: "hidden" }}>
									<ul className="nav nav-footer justify-content-center justify-content-lg-end">
									<li className="nav-item">
										<a href="https://www.creative-tim.com" className="nav-link text-muted" target="_blank">Creative Tim</a>
									</li>
									<li className="nav-item">
										<a href="https://www.creative-tim.com/presentation" className="nav-link text-muted" target="_blank">About Us</a>
									</li>
									<li className="nav-item">
										<a href="https://www.creative-tim.com/blog" className="nav-link text-muted" target="_blank">Blog</a>
									</li>
									<li className="nav-item">
										<a href="https://www.creative-tim.com/license" className="nav-link pe-0 text-muted" target="_blank">License</a>
									</li>
									</ul>
								</div>
								</div>
							</div>
						</footer>
					</div>
				</main>

				{/* Argon Configurator */}
				<div className="fixed-plugin">
					<a className="fixed-plugin-button text-dark position-fixed px-3 py-2">
						<i className="fa fa-cog py-2"> </i>
					</a>
					<div className="card shadow-lg">
						<div className="card-header pb-0 pt-3 ">
						<div className="float-start">
							<h5 className="mt-3 mb-0">Argon Configurator</h5>
							<p>See our dashboard options.</p>
						</div>
						<div className="float-end mt-4">
							<button className="btn btn-link text-dark p-0 fixed-plugin-close-button">
							<i className="fa fa-close"></i>
							</button>
						</div>
						{/* End Toggle Button */}
						</div>
						<hr className="horizontal dark my-1" />
						<div className="card-body pt-sm-3 pt-0 overflow-auto">
						{/* Sidebar Backgrounds */}
						<div>
							<h6 className="mb-0">Sidebar Colors</h6>
						</div>
						<a href="#" className="switch-trigger background-color">
							<div className="badge-colors my-2 text-start">
							<span className="badge filter bg-gradient-primary active" data-color="primary" onClick={changeDesign('sidebarColor(this)')}></span>
							<span className="badge filter bg-gradient-dark" data-color="dark" onClick={changeDesign('sidebarColor(this)')}></span>
							<span className="badge filter bg-gradient-info" data-color="info" onClick={changeDesign('sidebarColor(this)')}></span>
							<span className="badge filter bg-gradient-success" data-color="success" onClick={changeDesign('sidebarColor(this)')}></span>
							<span className="badge filter bg-gradient-warning" data-color="warning" onClick={changeDesign('sidebarColor(this)')}></span>
							<span className="badge filter bg-gradient-danger" data-color="danger" onClick={changeDesign('sidebarColor(this)')}></span>
							</div>
						</a>
						{/* Sidenav Type */}
						<div className="mt-3">
							<h6 className="mb-0">Sidenav Type</h6>
							<p className="text-sm">Choose between 2 different sidenav types.</p>
						</div>
						<div className="d-flex">
							<button className="btn bg-gradient-primary w-100 px-3 mb-2 active me-2" onClick={changeDesign('sidebarType(this)')}>White</button>
							<button className="btn bg-gradient-primary w-100 px-3 mb-2" onClick={changeDesign('sidebarType(this)')}>Dark</button>
						</div>
						<p className="text-sm d-xl-none d-block mt-2">You can change the sidenav type just on desktop view.</p>
						{/* Navbar Fixed */}
						<div className="d-flex my-3">
							<h6 className="mb-0">Navbar Fixed</h6>
							<div className="form-check form-switch ps-0 ms-auto my-auto">
							<input className="form-check-input mt-1 ms-auto" type="checkbox" id="navbarFixed" onClick={changeDesign('navbarFixed(this)')} />
							</div>
						</div>
						<hr className="horizontal dark my-sm-4" />
						<div className="mt-2 mb-5 d-flex">
							<h6 className="mb-0">Light / Dark</h6>
							<div className="form-check form-switch ps-0 ms-auto my-auto">
							<input className="form-check-input mt-1 ms-auto" type="checkbox" id="dark-version" onClick={changeDesign('darkMode(this)')} />
							</div>
						</div>
						<a className="btn bg-gradient-dark w-100" href="https://www.creative-tim.com/product/argon-dashboard">Free Download</a>
						<a className="btn btn-outline-dark w-100" href="https://www.creative-tim.com/learning-lab/bootstrap/license/argon-dashboard">View documentation</a>
						<div className="w-100 text-center">
							<a className="github-button" href="https://github.com/creativetimofficial/argon-dashboard" data-icon="octicon-star" data-size="large" data-show-count="true" aria-label="Star creativetimofficial/argon-dashboard on GitHub">Star</a>
							<h6 className="mt-3">Thank you for sharing!</h6>
							<a href="https://twitter.com/intent/tweet?text=Check%20Argon%20Dashboard%20made%20by%20%40CreativeTim%20%23webdesign%20%23dashboard%20%23bootstrap5&amp;url=https%3A%2F%2Fwww.creative-tim.com%2Fproduct%2Fargon-dashboard" className="btn btn-dark mb-0 me-2" target="_blank">
							<i className="fab fa-twitter me-1" aria-hidden="true"></i> Tweet
							</a>
							<a href="https://www.facebook.com/sharer/sharer.php?u=https://www.creative-tim.com/product/argon-dashboard" className="btn btn-dark mb-0 me-2" target="_blank">
							<i className="fab fa-facebook-square me-1" aria-hidden="true"></i> Share
							</a>
						</div>
						</div>
					</div>
				</div>
            </>
        )
    }

    function Footer() {
        return (
            <>
            </>
        )
    }

    function Logout() {
        localStorage.setItem('user_id', '');
        localStorage.setItem('optimus', '');
        localStorage.setItem('first_name', '');
        localStorage.setItem('last_name', '');
        console.log('Logged out');
        navigate('/login');
        window.location.reload();
    }
};

export default Layout;

